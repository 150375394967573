<template>
  <div>
    <div class="container_wrap bottom_blank">

      <el-row type="flex" justify="center" align="right">
        <el-col :span="12">
          <h1 v-if="method=='single'">选择支付方式</h1>
          <h1 v-else>批量支付</h1>
        </el-col>
        <el-col :span="12" style="margin-top: 35px;text-align: right" >
          <div v-if="method=='single'">
            <span> 订单编号：{{order_info.order_sn}} </span> &nbsp;&nbsp;&nbsp;&nbsp;
            <span> 下单时间：{{order_info.created_at_local}} </span>
          </div>
        </el-col>
      </el-row>



      <div class="panel">
        <div class="header">支付方式</div>
        <div class="content">
          <el-row :gutter="20">
            <el-col :span="6" v-if="order_info.order_amount > 0">
              <el-radio v-model="payment_method" label="alipay" border class="radio_way">
                <i class="iconfont icon-iconfontrectangle390 zfb"></i>
                <span>支付宝支付</span>
              </el-radio>
            </el-col>
            <el-col :span="6"  v-if="order_info.order_amount > 0">
              <el-radio v-model="payment_method" label="wxpay" border class="radio_way">
                <i class="iconfont icon-weixinzhifu wx"></i>
                <span>微信支付</span>
              </el-radio>
            </el-col>
            <el-col :span="6" v-if="order_info.order_amount > 0">
              <el-radio v-model="payment_method" label="offline" border class="radio_way">
                <i class="iconfont icon-tongyong-lianxikefutubiao kf"></i>
                <span>联系客服线下付款</span>
              </el-radio>
            </el-col>
            <el-col :span="6" v-if="order_info.order_amount === 0">
              <el-radio v-model="payment_method" label="free" border class="radio_way">
                <span>无需支付</span>
              </el-radio>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-table :data="order_info.order_goods" class="order_table_common" v-if="method=='single'">
        <el-table-column prop="info" label="商品信息">
          <template #default="scope">

            <!-- 课程 -->
            <div class="course_item_common pointer"  v-if="scope.row.goods_type == 'curriculum'">
              <div class="cover" @click="goCurDetail(scope.row.goods_info.cur_uuid)">
                <img :src="scope.row.cur_cover" />
                <span class="hot" v-if="scope.row.cur_label && scope.row.cur_label != 'null'">{{scope.row.cur_label}}</span>
                <span class="tag" v-if="scope.row.cur_type">{{ scope.row.cur_type }}</span>
                <div class="layer" v-if="scope.row.goods_info.state_text != '已上架'">已失效</div>
              </div>
              <div class="content">
                <h4 class="text_ellipsis" :title="scope.row.goods_info.name" @click="goCurDetail(scope.row.goods_info.cur_uuid)">{{ scope.row.goods_info.name }}</h4>
                <div class="info">
                  章节：{{ scope.row.chapter_desc }}
                </div>
              </div>
            </div>

            <!-- 会员卡 -->
            <div v-if="scope.row.goods_type == 'vip'">

              <VipCard :vip_card="scope.row.goods_info"
                       :expired_at="scope.row.goods_info.expired_detail_text"
                       :price_visible="false" :btn_buy="false" :btn_detail="false"></VipCard>

            </div>

          </template>
        </el-table-column>
        <el-table-column prop="price" label="价格" width="150">
          <template #default="scope">
            ¥ {{ scope.row.goods_total }}
          </template>
        </el-table-column>
        <el-table-column prop="discount" label="优惠" width="180">
          <template #default="scope">
            <div v-if="scope.row.discount_amount > 0">
              <div style="color:#f51c21;">-¥{{ scope.row.discount_amount }}</div>
              <div>{{ scope.row.promote_desc }}</div>
              <div v-if="scope.row.expired_days">学习有效期：{{scope.row.expired_days}}天</div>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="free" label="客服减免" width="180" v-if="order_info.reduce_amount > 0">
          <template #default="scope">
            <div style="color:#f51c21;" v-if="scope.row.reduce_discount > 0">-¥{{ scope.row.reduce_discount }}</div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column prop="pay" label="实付" width="150">
          <template #default="scope">
            <div style="color:#f51c21;">¥ {{ scope.row.amount }}</div>
          </template>
        </el-table-column>
      </el-table>

      <el-table :data="mixed_order_list" class="order_table_common" v-if="method=='mixed'">
        <el-table-column prop="info" label="序号" type="index"></el-table-column>
        <el-table-column prop="type_text" label="订单类型" ></el-table-column>
        <el-table-column prop="order_sn" label="订单编号" width="200"></el-table-column>
        <el-table-column prop="goods_total_count" label="商品数量"></el-table-column>
        <el-table-column prop="total_amount" label="商品金额">
          <template #default="scope">
            ¥ {{scope.row.total_amount}}
          </template>
        </el-table-column>
        <el-table-column prop="discount_amount" label="优惠金额">
          <template #default="scope">
            <span v-if="scope.row.discount_amount"> ¥ {{scope.row.discount_amount}} </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="discount_amount" label="客服减免" v-if="order_info.reduce_amount > 0">
          <template #default="scope">
            <span v-if="scope.row.reduce_amount > 0"> ¥ {{scope.row.reduce_amount}} </span>
            <span v-else> - </span>
          </template>
        </el-table-column>
        <el-table-column prop="order_amount" label="实付金额">
          <template #default="scope">
            ¥ {{scope.row.order_amount}}
          </template>
        </el-table-column>
        <el-table-column prop="state_text" label="订单状态"></el-table-column>
      </el-table>

    </div>
    <transition name="el-fade-in-linear">
      <div class="settle_wrap" v-show="isShow">
        <div class="container_wrap">
          <span>共 {{order_info.order_goods_count}} 个商品，
            商品金额：¥ {{order_info.total_amount}}，
            优惠金额：¥ {{order_info.discount_amount}}，
          </span>
          <span v-if="order_info.reduce_amount > 0">
              客服减免：¥ {{order_info.reduce_amount}}，
          </span>
          <span>实付金额：</span>
          <span class="price">¥{{order_info.order_amount}}</span>
          <el-link v-if="method==='single' && order_info.state_text != '待收款'"
                   :underline="false" href="javascript:;"
                   @click="confirmCancelOrder(order_uuid)">取消订单</el-link>
          <el-button type="danger" round @click="pullUpOrderPayments">
            <span v-if="order_info.order_amount > 0">{{payment_btn_text}}</span>
            <span v-else>完成订单</span>
            <Countdown v-if="method=='single' && order_info.state_text != '待收款'"
                       :remainTime="order_info.cancel_seconds"
                       @countDowmEnd="countDownEnd" class="countdown"></Countdown>
          </el-button>
        </div>
      </div>
    </transition>

    <el-dialog title="支付订单" v-model="paymentDialogVisible" :close-on-click-modal="false" :close-on-press-escape="false" width="400px">
      <div class="text-center" style="margin-bottom:20px;">支付方式：{{ payment_method_text }}</div>
      <div class="text-center">
        <!-- 支付宝 -->
        <div v-if="payment_method == 'alipay'">
          <el-alert style="line-height: 2;margin-bottom:20px;"
          title="请在新打开的支付页面完成支付"
          description="支付完成前请不要关闭此窗口" center
          type="info" :closable="false"></el-alert>

          <el-alert style="line-height: 2;margin-bottom:20px;"
                    :title="alipay_notice_msg" v-if="alipay_notice_visible"
                    center type="warning" :closable="false"></el-alert>

          <el-button @click="paymentDialogVisible=false">重新选择支付方式</el-button>
          <el-button type="primary" @click="checkCurrentPaymentState">支付完成</el-button>

        </div>

        <!-- 微信 -->
        <div v-if="payment_method == 'wxpay'">
          <img :src="wxpay_qr_code_url" style="display:block;width:60%;margin:0 auto 20px;" />
          <div style="line-height:2;">请使用微信扫描二维码支付<br />￥ {{order_info.order_amount}}</div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import Countdown from 'components/common/Countdown'
  import VipCard from "components/settlement/VipCard"
  import {detailOrder, cancelOrder, closeOrder, fmtUserOrderInfo} from 'api/settlement/order/user'
  import {fmtOrderInfo} from 'api/settlement/order/admin'
  import {pullUpPayment, queryPayment, confirmMixedPayment} from 'api/settlement/payment/user'
  import {fmtPaymentInfo} from 'api/settlement/payment/admin'
  import { db_time_to_local_fmt } from 'utils/timeutils'
  import { is_mobile, is_weixin } from 'utils/common'
  import { ElLoading } from 'element-plus'
  import { showConfirmMessageBox, showMessage } from 'api/base/message'
  export default {
    components: {
      Countdown,
      VipCard
    },
    data() {
      return {
        method: 'single',
        order_uuid: '',
        payment_uuid: '',
        payment_confirm_key: '',
        mixed_order_list: [],
        dashboard_base_url: null,
        order_uuid_list: [],
        order_info: {},
        payment_method: 'alipay',
        payment_method_text: '支付宝',
        payment_btn_text: '立即支付',
        wxpay_qr_code_url:'',
        alipay_notice_visible: false,
        alipay_notice_msg: '',
        wxpay_query_interval: null,
        isShow: false,
        paymentDialogVisible: false,
        countdown: 10,
        page_from: null,
        payment_dlg: null,
      }
    },
    created() {

      const that = this;

      if (this.$route.params.method != undefined && this.$route.params.method.length > 0) {
        this.method = this.$route.params.method;
      }

      if (!['single', 'mixed'].includes(this.method)) {
        this.$alert('非法操作', '标题名称', {
          confirmButtonText: '确定',
          callback: action => {
              that.goPath('/');
          }
        });
        return;
      }

      if (this.$route.query.from != undefined){
          this.page_from = this.$route.query.from;
      }

      if (this.$route.params.order_uuid != undefined) {

        if (is_mobile()) {
          window.location.href = `/mobile${location.pathname + location.search}`;
          return;
        }

        switch (this.method) {
          case 'single': { // 单个订单支付
            this.order_uuid = this.$route.params.order_uuid;
            this.loadOrderInfo();
          } break;
          case 'mixed': { // 组合支付
              this.payment_confirm_key = this.$route.params.order_uuid;
              this.loadPaymentInfo();
          } break;
        }

      }

    },
    methods: {
      async loadOrderInfo(){

        const that = this;
        await detailOrder(this.order_uuid).then(result => {

          if (result.succeed) {

            that.dashboard_base_url = result.dashboard_base_url;

            let filled_order_info = fmtUserOrderInfo(result.order_info);

            if (!['待支付', '待收款'].includes(filled_order_info.state_text)) {

              that.$alert(`当前订单状态为${filled_order_info.state_text},不能进行支付`, '提示', {
                confirmButtonText: '确定',
                callback: action => {

                    switch(that.page_from){
                      case 'order_center': {
                          window.location.href = '/center/order/';
                      } break;
                      default: {
                          window.location.href = '/';
                      } break;
                    }

                }
              });

              return;
            }

            console.log(filled_order_info);

            that.order_info = filled_order_info;

            if (filled_order_info.order_amount == 0){
                this.payment_method = 'free';
            }

            this.order_uuid_list = [that.order_uuid];

            setTimeout(()=>{
              that.isShow = true;
            }, 300);
          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      async loadPaymentInfo(){

          const that = this;
          await confirmMixedPayment(this.payment_confirm_key).then(result=>{

            if (result.succeed) {

                that.mixed_order_list = [];

                that.order_uuid_list = [];
                result.order_list.forEach(item=>{
                     that.mixed_order_list.push(fmtOrderInfo(item));
                     that.order_uuid_list.push(item.uuid);

                     console.log(item);
                });


                that.order_info = {
                  total_amount: result.total_amount,
                  discount_amount: result.discount_amount,
                  reduce_amount: result.reduce_amount,
                  order_amount: result.order_amount,
                  order_goods_count: result.order_goods_count
                };

                if(result.order_amount === 0){
                    that.payment_method = 'free';
                }

                that.isShow = true;

            } else {

              that.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定',
                callback: action => {
                  window.location.href = '/course/list';
                }
              });

            }

          }).catch(err=>{
              showMessage(err, 'error');
          });

      },
      goCurDetail(cur_uuid){
        const cur_detail_page = this.$router.resolve({name: 'CourseDetail', params: {uuid: cur_uuid}});
        window.open(cur_detail_page.href, '_blank');
      },
      confirmCancelOrder(order_uuid){

        const self = this;

        this.$confirm('您确定要取消该订单吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          type: 'warning'
        }).then(() => {
          self.cancelOrderByUUID(order_uuid);
        }).catch(() => {})
      },

      async cancelOrderByUUID(order_uuid){

        const that = this;

        await cancelOrder({'uuid_in': [order_uuid]}).then(result => {

          if (result.succeed) {

              this.goPath(`/order/result/${order_uuid}`)

          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },

      async closeOrderByUUID(order_uuid){

        const that = this;

        await closeOrder({'uuid_in': [order_uuid]}).then(result => {

          if (result.succeed) {

              this.goPath(`/order/result/${order_uuid}`)

          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      goPath(path) {
        this.$router.push(path);
      },
      countDownEnd() {
        this.closeOrderByUUID(this.order_uuid);
      },
      pullUpOrderPayments(){

        const that = this;

        if (this.order_uuid_list.length > 0) {

          let loadingInstance = ElLoading.service({
            fullscreen: true,
            text: '正在拉起支付, 请稍后 ...'
          });

          pullUpPayment({
            'order_uuid_in': this.order_uuid_list,
            'payment_type': this.payment_method,
            'is_mobile': is_mobile(),
            'is_weixin': is_weixin(),
          }).then(result=>{

            if (result.succeed) {

                console.log(result);

                let payment_instance = result.payment_instance;
                let payment_url = result.payment_url;

                that.payment_uuid = payment_instance.uuid;

                let online_payment = false;

                switch(this.payment_method){
                  case 'alipay': {

                      window.open(payment_url);
                      that.payment_method_text = '支付宝';
                      that.paymentDialogVisible = true;
                      online_payment = true;
                  } break;
                  case 'wxpay': {
                      that.wxpay_qr_code_url = payment_url;
                      that.payment_method_text = '微信支付';
                      that.paymentDialogVisible = true;
                      online_payment = true;

                      that.wxpay_query_interval = setInterval(that.checkCurrentPaymentState, 3000);

                  } break;
                  case 'offline':
                  case 'free': {
                    this.goPath(`/order/result/payment/${payment_instance.uuid}`)
                  } break;

                }

                if(online_payment && result.uncashed_mode){
                    this.order_info.state_text = '待支付';
                    this.order_info.cancel_seconds = result.overdue_seconds;
                }

            } else {

              that.$alert(`${result.error_msg}`, '提示', {
                confirmButtonText: '确定'
              });

            }

            loadingInstance.close();

          }).catch(err=>{

             loadingInstance.close();

            this.$alert(`${err}`, '提示', {
              confirmButtonText: '确定'
            });
          });

        } else {
          this.$alert('未知错误,不能进行支付', '提示', {
            confirmButtonText: '确定'
          });
        }



      },

      show_payment_dialog(payment_url) {

        let encode_url = encodeURIComponent(payment_url);

        let dialog_src = `/api/pay/payment_dialog/?payment_url=${encode_url}`;

        var myTop = screen.availHeight * 0.1;
        var myLeft = screen.availWidth * 0.1;
        var strWindowFeatures = "width=1024,height=576,top=" + myTop + ",left=" + myLeft +
          ",toolbar=no,menubar=no,location=no,resizable=yes,scrollbars=yes,status=1";
        return window.open(dialog_src, 1, strWindowFeatures);

      },

      checkCurrentPaymentState(){

        const that = this;

        queryPayment(this.payment_uuid).then(result => {

          if (result.succeed) {

            let payment_instance = fmtPaymentInfo(result.instance);

            switch (payment_instance.state_text) {
              case '已收款' : {
                  this.goPath(`/order/result/payment/${that.payment_uuid}`);
                  clearInterval(that.wxpay_query_interval);
              } break;
              case '待收款' : {
                  if(that.payment_method == 'alipay'){
                      that.alipay_notice_msg = '尚未完成支付, 请先完成支付';
                      that.alipay_notice_visible = true;
                  }
              } break;

              default: {
                that.$alert(`${result.error_msg}`, '提示', {
                  confirmButtonText: '确定'
                });
              } break;
            }

          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      }

    },
    watch: {
        paymentDialogVisible(val){
            if(!val){
                clearInterval(this.wxpay_query_interval)
            }
        },
        payment_method(val){
            switch(val){
              case 'alipay':
              case 'wxpay': {
                   this.payment_btn_text = '立即支付';
              } break;
              case 'offline': {
                  this.payment_btn_text = '确定';
              } break;
            }
        }
    }
  }
</script>

<style lang="scss" scoped>
  .bottom_blank {
    padding-bottom:70px;
  }
  h1 {
    font-size:30px;
    color:#333;
    margin:0;
    font-weight: normal;
    padding:20px 0 30px 0;
  }
  .panel {
    border:1px solid #EBEEF5;
    margin-bottom:20px;
    .header {
      background: #f6f6f6;
      padding:12px 10px;
      line-height: 23px;
    }
    .content {
      padding:30px 10px;
    }
  }
  :deep(.radio_way) {
    display:flex;
    height:102px;
    padding-top:30px;
    padding-bottom:30px;
    padding-left:20px;
    .el-radio__input {
      display:flex;
      align-items: center;
    }
    .el-radio__label {
      flex:1;
      padding-left:20px;
      i {
        font-size:40px;
        vertical-align: middle;
        margin-right:15px;
        &.zfb {
          color:#02a9f1;
        }
        &.wx {
          color:#09bb07;
        }
        &.kf {
          color:#00adef;
        }
      }
      span {
        vertical-align: middle;
        color:#666;
        line-height: 40px;
      }
    }
  }
  .settle_wrap {
    padding-top:20px;
    padding-bottom:20px;
    text-align:right;
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    background:#fff;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    z-index:1;
    span, .el-button {
      vertical-align: middle;
    }
    span.price {
      margin-left:15px;
      font-size:42px;
      color:#f51c21;
      font-family: 'font-540';
      vertical-align: middle;
      margin-right:60px;
    }
    .el-link {
      font-size:12px;
      margin-right:20px;
    }
    .el-button {
      font-size:16px;
      padding:10px 50px;
      border-radius:100px;
      .countdown {
        display:block;
        color:#fff;
        font-size:12px;
        text-align:center;
        margin-top:5px;
      }
    }
  }
  :deep(.vipcard_item) {
    margin:0;
    max-width:450px;
  }
</style>
